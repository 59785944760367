import React from "react"

import Layout from "../../../components/Layout/layout"
import SEO from "../../../components/SEO/seo"
import HeroInner from "../../../components/HeroInner/hero-inner"
import ArticleFullWidth from "../../../components/ArticleFullWidth/article-full-width"
import UnorderedList from "../../../components/UnorderedList/unordered-list"

const PrintOnDemand = () => (
  <Layout>
    <SEO title="Print on Demand" />
    <HeroInner
      title="Print on Demand"
      imageAlt="Print on Demand"
      imagePath="services/custom-services/print-on-demand/hero.jpg"
    />
    <ArticleFullWidth
      descriptions={[
        `Aside from being an efficient and cost-effective way to print high-quality books, print on demand is also incredibly flexible. Whatever your objectives, Blend-Divine has a print and distribution model to fit your needs. From order to production to fulfillment, we have you covered with wholesale solutions, global distribution, and custom publishing.`,
      ]}
    />
    <div className="container mb40">
      <UnorderedList
        items={[
          `Incredibly scalable, print-on-demand from Blend-Divine is designed to help you not only cut the cost of printing, but also expand your market reach and optimize profits on every book sold, whether one copy or a Million.`,
          `Improve inventory control and significantly reduce overhead by eliminating warehousing costs. `,
          `Fast turn-around times. Most book orders print and ship within 72 hours.`,
          `Revive backlist sales and ensure every title in your catalog is always in-print so you never miss a sale. `,
          `Fulfillment specialists working behind the scenes to ensure customers get their orders on time.`,
          `A technological publishing feat of innovation, on-demand book printing cuts the distance and time between you and your customers by only printing what you need, when you need it.`,
        ]}
      />
    </div>
  </Layout>
)

export default PrintOnDemand
